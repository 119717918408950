import * as React from 'react';
import * as styles from './home.module.css';
import Page from './page';
import photo from '../../images/annie.png';
import LinkButton from './link-button';
import resume from '../../files/resume.pdf';
import { COLORS } from '../../constants';

function Home({ refElement }) {
  return (
    <Page refElement={refElement}>
      <div className={styles.homePage}>
        <img
          className={styles.photo}
          src={photo}
          alt="A photo of Annie Sanford."
          width={160}
          height={160}
        />
        <div className={styles.nameContainer}>
          <h1 className={styles.name}>
            <span>Annie</span>
            <span>Sanford</span>
          </h1>
        </div>
      </div>

      <p className={styles.aboutText}>
        I am a current HCI Design masters student at City, University of London.
        I have made versatility a hobby - having majored in sociology and
        communication studies with a focus in business & marketing in my
        undergraduate degree, I then went on to work as a research project
        manager at a tech company and, three years later, landed in HCl and UX.
        At the core of everything I do is my passion for working with people and
        keeping them in the center of it all. My ultimate goal is to become a
        product design decision-maker - I am currently pursuing internship
        opportunities that will help me get there after completing my masters
        program.
      </p>
      <p className={styles.aboutText}>
        An American on paper but a Londoner at heart, I am currently based in
        London, U.K., where I have the right to work full-time on a Tier 2
        spousal visa. I live in Camden with my partner, a software engineer at
        Spotify, and our 3-year-old dog. I am a fan of long walks through the
        many parks of West/NW London, exploring the diverse London food scene,
        watching fantasy, sci-fi, historical fiction, and comedy shows,
        traveling (pre-covid), and the occasional crafty hobby or two.
      </p>

      <LinkButton
        href={resume}
        downloadFileName="AnnieSanford_Resume.pdf"
        color={COLORS.home}
      >
        See my resume
      </LinkButton>
    </Page>
  );
}

export default Home;
