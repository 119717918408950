import { Box } from '@mui/material';
import * as React from 'react';
import * as styles from './link-button.module.css';

function LinkButton({
  href,
  children,
  downloadFileName = '',
  color,
  textColor = undefined,
}) {
  return (
    <Box
      sx={{ backgroundColor: color, color: textColor }}
      component="a"
      className={styles.link}
      href={href}
      {...(downloadFileName ? { download: downloadFileName } : {})}
    >
      {children}
    </Box>
  );
}

export default LinkButton;
